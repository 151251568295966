import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Button,
    ButtonGroup,
    Label
} from "reactstrap";

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import {
    getSociety,
    setSociety,
    editSociety,
    apiError,
    getSaleStats
} from '../../../store/actions';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import DatePicker from "react-datepicker";

var moment = require('moment');

class SaleSummaryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_edit: {
                date_start: moment().subtract('1', "month").format("DD/MM/YYYY"),
                date_end: moment.parseZone(new Date()).local().format("DD/MM/YYYY"),
            },
            date_start: (moment().subtract('1', "month").toDate()),
            date_end: new Date(),
            date_current: new Date(),
            stats_ticket: [],
            stats_subscription: [],
            subscription_general: []
        };
    }

    async componentDidMount() {
        this.loadStats();
    }

    loadStats = () => {
        getSaleStats(this.state.form_edit.date_start, this.state.form_edit.date_end).then(result => {
            console.log("result")
            console.log(result)
            if (!result) {
                this.setState({stats_ticket: [], stats_subscription: [], subscription_general: []});
            } else {
                this.setState({
                    stats_ticket: result.total_ticket,
                    stats_subscription: result.total_subscription,
                    // subscription_general: result.subscription_general
                });
            }
        })
    }

    setDate = (date, type) => {
        console.log(date);
        let date_start = this.state.date_start;
        let date_end = this.state.date_end;
        if (type === "start")
            date_start = date;
        else
            date_end = date;

        if (date_start > date_end)
            date_end = date_start;
        let form_edit = this.state.form_edit;
        form_edit.date_start = moment.parseZone(date_start).local().format("DD/MM/YYYY");
        form_edit.date_end = moment.parseZone(date_end).local().format("DD/MM/YYYY");

        this.setState({date_start: date_start, date_end: date_end, form_edit: form_edit});
    }

    renderTicketStats = () => {
        let res = [];
        let total_quantity = 0;
        let total_clients = 0;
        let total_price = 0;
        let total_service_fee = 0;
        for (let i in this.state.stats_ticket) {
            let stat = this.state.stats_ticket[i];
            total_quantity += stat.total_quantity;
            total_clients += stat.total_clients;
            total_price += parseFloat(stat.total_price);
            total_service_fee += parseFloat(stat.total_service_fee);
        }
        res.push(<Col lg="4">
            <Card>
                <CardBody>
                    <h4>Totale Ticket</h4>
                    Totale ticket venduti: <b>{total_quantity}</b><br/>
                    Totale clienti unici: <b>{total_clients}</b><br/>
                    Guadagni ticket venduti: <b>{parseFloat(total_price).toFixed(2)}€</b><br/>
                    Guadagni commissione di servizio: <b>{parseFloat(total_service_fee).toFixed(2)}€</b><br/>
                </CardBody>
            </Card>
        </Col>)

        for (let i in this.state.stats_ticket) {
            let stat = this.state.stats_ticket[i];
            res.push(<Col lg="4">
                <Card>
                    <CardBody>
                        <h4>Ticket {stat.category.name}</h4>
                        Totale ticket venduti: <b>{stat.total_quantity}</b><br/>
                        Totale clienti unici: <b>{stat.total_clients}</b><br/>
                        Guadagni ticket venduti: <b>{parseFloat(stat.total_price).toFixed(2)}€</b><br/>
                        Guadagni commissione di servizio: <b>{parseFloat(stat.total_service_fee).toFixed(2)}€</b><br/>
                    </CardBody>
                </Card>
            </Col>)
        }
        return res;
    }

    renderSubscriptionStats = () => {
        let stat = this.state.stats_subscription;
        return <Col lg="4">
            <Card>
                <CardBody>
                    <h4>Totale abbonamenti</h4>
                    Totale abbonamenti: <b>{stat.total_subscription}</b><br/>
                    Totale abbonamenti già pagati: <b>{stat.total_payed}</b><br/>
                    Totale abbonamenti sospesi: <b>{stat.total_suspended}</b><br/>
                </CardBody>
            </Card>
        </Col>
    }

    renderComparison = () => {
        let stat_sub = this.state.stats_subscription;

        let res = [];
        let total_quantity = 0;
        let total_clients = 0;
        for (let i in this.state.stats_ticket) {
            let stat = this.state.stats_ticket[i];
            total_quantity += stat.total_quantity;
            total_clients += stat.total_clients;
        }

        res.push(<Col lg="4">
            <Card>
                <CardBody>
                    <h4>Comparazione sul totale dei ticket</h4>
                    <b>Paganti</b> <small>(incluso utenti in prova)</small><br/>
                    Abbonamenti rispetto ai
                    ticket: <b>{((stat_sub.total_subscription - total_quantity) / total_quantity * 100 + 100).toFixed(3)}%</b><br/>
                    Abbonamenti rispetto ai
                    clienti: <b>{((stat_sub.total_subscription - total_clients) / total_clients * 100 + 100).toFixed(3)}%</b><br/><br/>
                    <b>PAGATI</b> <small>(escludendo utenti in prova)</small><br/>
                    Abbonamenti rispetto ai
                    ticket: <b>{((stat_sub.total_payed - total_quantity) / total_quantity * 100 + 100).toFixed(3)}%</b><br/>
                    Abbonamenti rispetto ai
                    clienti: <b>{((stat_sub.total_payed - total_clients) / total_clients * 100 + 100).toFixed(3)}%</b><br/>
                </CardBody>
            </Card>
        </Col>);

        for (let i in this.state.stats_ticket) {
            let stat = this.state.stats_ticket[i];
            total_quantity = stat.total_quantity;
            total_clients = stat.total_clients;
            res.push(<Col lg="4">
                <Card>
                    <CardBody>
                        <h4>Comparazione sul totale dei ticket {stat.category.name}</h4>
                        <b>Paganti</b> <small>(incluso utenti in prova)</small><br/>
                        Abbonamenti rispetto ai
                        ticket: <b>{((stat_sub.total_subscription - total_quantity) / total_quantity * 100 + 100).toFixed(3)}%</b><br/>
                        Abbonamenti rispetto ai
                        clienti: <b>{((stat_sub.total_subscription - total_clients) / total_clients * 100 + 100).toFixed(3)}%</b><br/><br/>
                        <b>PAGATI</b> <small>(escludendo utenti in prova)</small><br/>
                        Abbonamenti rispetto ai
                        ticket: <b>{((stat_sub.total_payed - total_quantity) / total_quantity * 100 + 100).toFixed(3)}%</b><br/>
                        Abbonamenti rispetto ai
                        clienti: <b>{((stat_sub.total_payed - total_clients) / total_clients * 100 + 100).toFixed(3)}%</b><br/>
                    </CardBody>
                </Card>
            </Col>);

        }
        return res;
    }

    renderDetails = () => {
        let cards = [];
        let details = this.state.subscription_general;
        if (Object.keys(details).length === 0)
            return;

        console.log("details")
        console.log(details)
        let active = details.active;
        let total_activation = details.active.total_activation;
        let count = details.active.count;
        cards.push(<Col lg="4">
            <Card>
                <CardBody>
                    <h4>Totale SOLO attivazioni</h4>
                    Totale: <b>{parseFloat(total_activation.total).toFixed(2)}€</b> su <b>{count.total}</b> abbonamenti<br/>
                    Totale
                    mensili: <b>{parseFloat(total_activation.total_monthly).toFixed(2)}€</b> su <b>{count.total_monthly}</b> abbonamenti<br/>
                    Totale
                    annuali: <b>{parseFloat(total_activation.total_yearly).toFixed(2)}€</b> su <b>{count.total_yearly}</b> abbonamenti<br/>
                    <small>(è il totale degli abbonamenti attivati, viene considerato solo il primo pagamento valido quindi esclusi quelli che non hanno superato il periodo di prova)</small>
                    <hr/>
                    Abbonamenti attivati in cross-selling: <b>{parseFloat(active.total.payment_auto).toFixed(2)}€</b> su <b>{count.payment_auto}</b> abbonamenti<br/>
                    Abbonamenti attivati sotto l'iscrizione: <b>{parseFloat(active.total.payment_subscription).toFixed(2)}€</b> <b>{count.payment_subscription}</b> abbonamenti<br/>
                </CardBody>
            </Card>
        </Col>);
        let total_subscription = details.active.total;

        cards.push(<Col lg="4">
            <Card>
                <CardBody>
                    <h4>Totale attivazioni con RINNOVI</h4>
                    Totale: <b>{parseFloat(total_subscription.total).toFixed(2)}€</b> su <b>{count.total}</b> abbonamenti<br/>
                    Totale
                    mensili: <b>{parseFloat(total_subscription.total_monthly).toFixed(2)}€</b> su <b>{count.total_monthly}</b> abbonamenti<br/>
                    Totale
                    annuali: <b>{parseFloat(total_subscription.total_yearly).toFixed(2)}€</b> su <b>{count.total_yearly}</b> abbonamenti<br/>

                    <hr/>
                    <h6>Apple</h6>
                    Totale: <b>{parseFloat(total_subscription.subscription_apple.total).toFixed(2)}€</b> su <b>{count.subscription_apple.total}</b> abbonamenti<br/>
                    Totale
                    mensili: <b>{parseFloat(total_subscription.subscription_apple.total_monthly).toFixed(2)}€</b> su <b>{count.subscription_apple.total_monthly}</b> abbonamenti<br/>
                    Totale
                    annuali: <b>{parseFloat(total_subscription.subscription_apple.total_yearly).toFixed(2)}€</b> su <b>{count.subscription_apple.total_yearly}</b> abbonamenti<br/>

                    <hr/>
                    <h6>PayPal</h6>
                    Totale: <b>{parseFloat(total_subscription.subscription_paypal.total).toFixed(2)}€</b> su <b>{count.subscription_paypal.total}</b> abbonamenti<br/>
                    Totale
                    mensili: <b>{parseFloat(total_subscription.subscription_paypal.total_monthly).toFixed(2)}€</b> su <b>{count.subscription_paypal.total_monthly}</b> abbonamenti<br/>
                    Totale
                    annuali: <b>{parseFloat(total_subscription.subscription_paypal.total_yearly).toFixed(2)}€</b> su <b>{count.subscription_paypal.total_yearly}</b> abbonamenti<br/>


                    <hr/>
                    <h6>Stripe</h6>
                    Totale: <b>{parseFloat(total_subscription.subscription_stripe.total).toFixed(2)}€</b> su <b>{count.subscription_stripe.total}</b> abbonamenti<br/>
                    Totale
                    mensili: <b>{parseFloat(total_subscription.subscription_stripe.total_monthly).toFixed(2)}€</b> su <b>{count.subscription_stripe.total_monthly}</b> abbonamenti<br/>
                    Totale
                    annuali: <b>{parseFloat(total_subscription.subscription_stripe.total_yearly).toFixed(2)}€</b> su <b>{count.subscription_stripe.total_yearly}</b> abbonamenti<br/>
                </CardBody>
            </Card>
        </Col>);

        let suspended = details.suspended;

        cards.push(<Col lg="4">
            <Card>
                <CardBody>
                    <h4>Le attivazioni di questo periodo che sono stati SOSPESI</h4>
                    <b>{suspended.payed.count}</b> abbonamenti che hanno pagato e dopo lo hanno sospeso.<br/>
                    <b>{suspended.payed.count_yearly}</b> annuali che hanno pagato e dopo lo hanno sospeso.<br/>
                    <b>{suspended.payed.count_monthly}</b> mensili che hanno pagato e dopo lo hanno sospeso.<br/>
                    <hr/>
                    <b>{suspended.not_payed.count}</b> abbonamenti che hanno attivato la prova e dopo lo hanno sospeso.<br/>
                    Totale non ricavato da questi abbonamenti: <b>-{parseFloat(suspended.not_payed.total).toFixed(2)}€</b><br/>
                </CardBody>
            </Card>
        </Col>);


        return cards;

    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumb title="Geoticket" breadcrumbItem="Statistiche vendite"/>
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="form-group">
                                            <Label for="society">Statistiche vendite</Label>
                                            <div className={"row"}>
                                                <div className="form-group col-md-4">
                                                    <DatePicker selected={this.state.date_start}
                                                                startDate={this.state.date_current}
                                                                className="form-control"
                                                                onChange={(date) => this.setDate(date, "start")}
                                                                dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <DatePicker selected={this.state.date_end}
                                                                minDate={this.state.date_start} className="form-control"
                                                                onChange={(date) => this.setDate(date, "end")}
                                                                dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                <div className="form-group col-md-4" style={{display: "flex"}}>
                                                    <div style={{marginTop: "auto"}}>
                                                        <ButtonGroup>
                                                            <Button onClick={() => this.loadStats()}
                                                                    color={"success"}>Aggiorna</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <small>Data avvio implementazione abbonamenti <b>04-08-2023</b></small><br/>
                                            <small>Data avvio implementazione commissione di servizio <b>21-03-2023</b></small>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            {this.renderTicketStats()}
                            {this.renderSubscriptionStats()}
                        </Row>
                        <hr/>
                        <h4>Comparazione abbonamenti</h4>
                        <Row>
                            {this.renderComparison()}
                        </Row>
                    {/*    <h4>Dettagli abbonamenti</h4>
                        <Row>
                            {this.renderDetails()}
                        </Row>*/}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const {rows, error, success} = {rows: [], error: false, success: false};
    return {rows, error, success};
}

export default withRouter(
    connect(
        mapStatetoProps, {getSociety, setSociety, editSociety, apiError}
    )
    (SaleSummaryPage));